import React, {useContext, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {AppBar, Avatar, Button, CssBaseline, Stack, Toolbar, Typography, Drawer, IconButton} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {AuthContext} from "../store/auth-context";
import logo from "../img/logo.png"

const Navbar = () => {
    const authCtx = useContext(AuthContext);
    const isLoggedIn = authCtx.isLoggedIn;
    const navigate = useNavigate();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const drawerStyles = {
        width: '250px',
        padding: '20px'
    };


    const toggleDrawer = () => {
        setDrawerOpen(prevState => !prevState);
    };


    const handleLogout = () => {
        authCtx.logout();
        navigate("/login");
    }

    return (
            <>
                <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer} style={drawerStyles}>
                <Button color="success" component={NavLink} to="/" sx={{color: '#000'}}>
                                Dashboard
                            </Button>
                            <Button component={NavLink} to="/servers" sx={{color: '#000'}}>
                                Servers
                            </Button>
                            <Button component={NavLink} to="/mods" sx={{color: '#000'}}>
                                Mods
                            </Button>
                            <Button component={NavLink} to="/scenarios" sx={{color: '#000'}}>
                                Scenarios
                            </Button>
                            <Button component={NavLink} to="/config" sx={{color: '#000'}}>
                                App config
                            </Button>
                            <Button component={NavLink} to="/additionalServers" sx={{color: '#000'}}>
                                Additional servers
                            </Button>
                            <Button onClick={handleLogout}
                                    sx={{color: '#fff'}}
                            >
                                Log out
                            </Button>
                </Drawer>

                {isLoggedIn && <AppBar position="static" sx={{mb: 4}}>
                    <CssBaseline/>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer} className="hamburger-menu">
    <MenuIcon />
</IconButton>

                        <Avatar
                                alt="Logo"
                                src={logo}
                                sx={{width: 52, height: 52, mr: 2}}
                        />
                        <Stack marginLeft={4}
                               direction="row"
                               justifyContent="flex-start"
                               alignItems="center"
                               spacing={1} className="navbar-items">
                            <Button color="success" component={NavLink} to="/" sx={{color: '#fff'}}>
                                Dashboard
                            </Button>
                            <Button component={NavLink} to="/servers" sx={{color: '#fff'}}>
                                Servers
                            </Button>
                            <Button component={NavLink} to="/mods" sx={{color: '#fff'}}>
                                Mods
                            </Button>
                            <Button component={NavLink} to="/scenarios" sx={{color: '#fff'}}>
                                Scenarios
                            </Button>
                            <Button component={NavLink} to="/config" sx={{color: '#fff'}}>
                                App config
                            </Button>
                            <Button component={NavLink} to="/additionalServers" sx={{color: '#fff'}}>
                                Additional servers
                            </Button>
                            <Button onClick={handleLogout}
                                    sx={{color: '#fff'}}
                            >
                                Log out
                            </Button>
                        </Stack>
                    </Toolbar>
                </AppBar>
                }
            </>
    );
};

export default Navbar;