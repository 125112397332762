const workshopErrorStatusMap = {
    GENERIC: "Unidentified error. Make sure server off, and retry. If this error persists please contact SSU.",
    IO: "Error Writing File(s). Make sure server off, and retry. This is usually caused by a mod in use attempting to be updated, If errors persist please contact SSU.",
    NO_MATCH: "A mod was not found on the Workshop. Maybe deleted or private?",
    NO_SUBSCRIPTION: "The given Steam account doesn't have the game or its DLC installed.",
    TIMEOUT: "The request timed out, please retry.",
    WRONG_AUTH: "Incorrect Steam authorization. Please check username, password and Steam Guard token.",
    RATE_LIMIT: "Steam Login Ban, Too many logins. Please try again later (no sooner than 30 minutes after last attempt, if you try sooner this will reset the ban to 30minutes)."
}

export default workshopErrorStatusMap;